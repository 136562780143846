<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="样本户移民情况统计"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">县</div>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view4">
            <div class="firstTitletStyle leftTitletStyle">样本</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">户数（户）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">人口（人）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">人均耕地（亩）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">人均可支配收入（元）</div>
        </el-row>

        <!-- 家庭年收入 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">家庭年收入（元）</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col>
                <div class="leftTitletStyle">合计</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">经营性净收入</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">小计</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">农业净收入</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">林果业净收入</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">养殖业净收入</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">二三产业净收入</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">工资性收入</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">小计</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">其中打工</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">转移性收入</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">小计</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">其中,移民直补</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <div class="leftTitletStyle">财产性净收入</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">家庭人均消费支出（元）</div>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">移民满意度（%）</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">总体满意度</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">对直补发放的满意度</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">项目实施中移民参与</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">对项目实施效果的满意度</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">对移民申诉渠道和问题处理的满意度</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">对目前生产生活的总体满意度</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('sampleHouseholds') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('samplePopulation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('perCapitaFarmland') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('disposableIncomePerPerson') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 总计 -->
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalAnnualIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 小计1 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('operatingNetIncomeSubtotal') }}</div>
          </div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('agriculturalNetIncome') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('forestryNetIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('livestockNetIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('secondaryIndustryNetIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('wageIncomeSubtotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('wageIncomeFromLabor') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('transferIncomeSubtotal') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationSubsidyIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('propertyNetIncome') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('consumptionExpenditurePerPerson') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('overallSatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('directSubsidySatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationParticipationSatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('implementationEffectSatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('appealHandlingSatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('currentLivingSatisfaction') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle">
                            <el-cascader  :append-to-body="false"  
                              v-if="type == 'add' || type == 'edit'"
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                            <div class="box_view" v-else>
                              {{ col['county'] }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'sampleHouseholds'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'samplePopulation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 人均耕地 -->
                        <el-row v-if="key1 == 'perCapitaFarmland'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'disposableIncomePerPerson'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 家庭年收入 -->

                        <el-row v-if="key1 == 'totalAnnualIncome'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['agriculturalNetIncome']) * 1000 +
                                  Number(col['forestryNetIncome']) * 1000 +
                                  Number(col['livestockNetIncome']) * 1000 +
                                  Number(col['secondaryIndustryNetIncome']) * 1000 +
                                  Number(col['wageIncomeFromLabor']) * 1000 +
                                  Number(col['immigrationSubsidyIncome']) * 1000 +
                                  Number(col['propertyNetIncome']) * 1000) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'operatingNetIncomeSubtotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                (Number(col['agriculturalNetIncome']) * 1000 +
                                  Number(col['forestryNetIncome']) * 1000 +
                                  Number(col['livestockNetIncome']) * 1000 +
                                  Number(col['secondaryIndustryNetIncome'] * 1000)) /
                                  1000
                              }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'agriculturalNetIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'forestryNetIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'livestockNetIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'secondaryIndustryNetIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 工资性收入 -->
                        <el-row v-if="key1 == 'wageIncomeSubtotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{ Number(col['wageIncomeFromLabor']) }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'wageIncomeFromLabor'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 转移性收入 -->
                        <el-row v-if="key1 == 'transferIncomeSubtotal'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{ Number(col['immigrationSubsidyIncome']) }}
                            </div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrationSubsidyIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'propertyNetIncome'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 人均消费支出 -->
                        <el-row v-if="key1 == 'consumptionExpenditurePerPerson'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 移民满意度 -->
                        <el-row v-if="key1 == 'overallSatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}%</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'directSubsidySatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}%</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrationParticipationSatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}%</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'implementationEffectSatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}%</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'appealHandlingSatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}%</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'currentLivingSatisfaction'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>

                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }} %</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'
import {
  _SampleHouseholdStatisticAdd,
  _SampleHouseholdStatisticInfo,
  _SampleHouseholdStatisticEdit
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/ImmigrationStatistics.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
      this.listData[index].orderNo = obj.orderNo
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheTotal(name) {
      let sum = 0

      if (name === 'wageIncomeSubtotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele['wageIncomeFromLabor'])
        }
      } else if (name === 'transferIncomeSubtotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele['immigrationSubsidyIncome'])
        }
      } else if (name === 'totalAnnualIncome') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['agriculturalNetIncome']) * 100 +
              Number(ele['forestryNetIncome']) * 100 +
              Number(ele['livestockNetIncome']) * 100 +
              Number(ele['secondaryIndustryNetIncome']) * 100 +
              Number(ele['wageIncomeFromLabor']) * 100 +
              Number(ele['immigrationSubsidyIncome']) +
              Number(ele['propertyNetIncome']) * 100) /
            100
        }
      } else if (name === 'operatingNetIncomeSubtotal') {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum +=
            (Number(ele['agriculturalNetIncome']) * 100 +
              Number(ele['forestryNetIncome']) * 100 +
              Number(ele['livestockNetIncome']) * 100 +
              Number(ele['secondaryIndustryNetIncome']) * 100) /
            100
        }
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }

      return sum
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null,
            county: '',
            orderNo: null,
            sampleHouseholds: null,
            samplePopulation: null,
            perCapitaFarmland: null,
            disposableIncomePerPerson: null,
            totalAnnualIncome: null,
            operatingNetIncomeSubtotal: null,
            agriculturalNetIncome: null,
            forestryNetIncome: null,
            livestockNetIncome: null,
            secondaryIndustryNetIncome: null,
            wageIncomeSubtotal: null,
            wageIncomeFromLabor: null,
            transferIncomeSubtotal: null,
            immigrationSubsidyIncome: null,
            propertyNetIncome: null,
            consumptionExpenditurePerPerson: null,
            overallSatisfaction: null,
            directSubsidySatisfaction: null,
            immigrationParticipationSatisfaction: null,
            implementationEffectSatisfaction: null,
            appealHandlingSatisfaction: null,
            currentLivingSatisfaction: null,
            countyPids: ''
          }
          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let flag = this.listData.every(ele => ele.countyId)

      let listData = this.listData.map(ele => {
        return {
          ...ele,
          appealHandlingSatisfaction: ele.appealHandlingSatisfaction / 100,
          currentLivingSatisfaction: ele.currentLivingSatisfaction / 100,
          directSubsidySatisfaction: ele.directSubsidySatisfaction / 100,
          immigrationParticipationSatisfaction: ele.immigrationParticipationSatisfaction / 100,
          implementationEffectSatisfaction: ele.implementationEffectSatisfaction / 100,
          overallSatisfaction: ele.overallSatisfaction / 100
        }
      })

      switch (this.type) {
        case 'add':
          if (flag) {
            _SampleHouseholdStatisticAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _SampleHouseholdStatisticEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _SampleHouseholdStatisticInfo(params).then(res => {
        if (res.code == 200) {
          // console.log('===_SampleHouseholdStatisticInfo====', res.data)
          let ele = res.data
          let obj = {
            ...ele,
            appealHandlingSatisfaction: (ele.appealHandlingSatisfaction * 100).toFixed(2),
            currentLivingSatisfaction: (ele.currentLivingSatisfaction * 100).toFixed(2),
            directSubsidySatisfaction: (ele.directSubsidySatisfaction * 100).toFixed(2),
            immigrationParticipationSatisfaction: (ele.immigrationParticipationSatisfaction * 100).toFixed(2),
            implementationEffectSatisfaction: (ele.implementationEffectSatisfaction * 100).toFixed(2),
            overallSatisfaction: (ele.overallSatisfaction * 100).toFixed(2)
          }
          this.listData = [obj]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
